<template>
  <div>
    <teacher-payments :teacherPaymentSection="1" />
  </div>
</template>

<script>
import TeacherPayments from "@/components/TeacherPayments";

export default {
  name: "TeacherPaymentsList",
  components: {
    TeacherPayments
  }
};
</script>

<style scoped></style>
